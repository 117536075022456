import {
  alpha,
  createTheme as muiCreateTheme,
  darken,
  lighten,
  responsiveFontSizes,
  Theme,
  ThemeOptions
} from "@material-ui/core/styles";
import { PaletteColor, PaletteColorOptions } from "@material-ui/core/styles/createPalette";
import { Shadows } from "@material-ui/core/styles/shadows";
import { deepmerge } from "./utils/objects";

/* Logo */
export const THEME_PALETTE_LOGO = {
  yeti: "rgb(234, 237, 240)",
  darkness: "rgb(23, 29, 38)",
  darkness100: "rgb(232, 232, 233)",
  shrimp: "rgb(238, 154, 178)",
  blurple: "rgb(82, 99, 243)",
  blurple400: "rgb(82, 99, 243, .40)",
  corn: "rgb(255, 197, 80)",
  corn200: "rgba(255, 197, 80, .20)",
};

/* Palette */
export const THEME_PALETTE = {
  primary: THEME_PALETTE_LOGO.blurple,
  secondary: THEME_PALETTE_LOGO.shrimp,
  dialog: "#2F2E41",
  red: "rgb(217, 3, 3)",
  orange: "rgb(241, 143, 1)",
  yellow: "rgb(241, 211, 1)",
  green: "rgb(153, 194, 77)",
  blue: "#5562eb",
  grey: "rgb(159, 162, 163)",
  textGrey: "#757575",
  softGrey: "rgb(234, 235, 240)",
  white: "rgb(251, 252, 254)",
  black: THEME_PALETTE_LOGO.darkness,
  logo: THEME_PALETTE_LOGO,
  linkedin: "#0072B1",
  twitter: "#1DA1F2",
  warning: {
    light: "#F5E3BC",
    main: THEME_PALETTE_LOGO.corn,
    dark: "#7E590A",
  },
  error: {
    light: "#F6D5D5",
    main: "#B67777",
    dark: "#751919",
  },
};

// FIXME (IW): This has moved to user-configurable settings, reconcile all uses and delete
export const PRIORITIES_PALETTE: { [key: string]: PaletteColor } = {
  0: {
    main: "#FC9496",
    light: lighten("#FC9496", 0.5),
    dark: darken("#FC9496", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  1: {
    main: "#FCE1A1",
    light: lighten("#FCE1A1", 0.5),
    dark: darken("#FCE1A1", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  2: {
    main: "#8DDFAB",
    light: lighten("#8DDFAB", 0.5),
    dark: darken("#8DDFAB", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  3: {
    main: "#6DBCFE",
    light: lighten("#6DBCFE", 0.5),
    dark: darken("#6DBCFE", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  4: {
    main: "#BD82CC",
    light: lighten("#BD82CC", 0.5),
    dark: darken("#BD82CC", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  other: {
    main: "#9AA0A8",
    light: lighten("#9AA0A8", 0.5),
    dark: darken("#9AA0A8", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  default: {
    main: "#bdbdbd",
    light: "#f5f5f5",
    dark: "#212121",
    contrastText: THEME_PALETTE.black,
  },
};

const shadows: number[][][] = [
  [
    [0, 1, 3, 0],
    [0, 1, 1, 0],
    [0, 2, 1, -1],
  ],
  [
    [0, 1, 5, 0],
    [0, 2, 2, 0],
    [0, 3, 1, -2],
  ],
  [
    [0, 1, 8, 0],
    [0, 3, 4, 0],
    [0, 3, 3, -2],
  ],
  [
    [0, 2, 4, -1],
    [0, 4, 5, 0],
    [0, 1, 10, 0],
  ],
  [
    [0, 3, 5, -1],
    [0, 5, 8, 0],
    [0, 1, 14, 0],
  ],
  [
    [0, 3, 5, -1],
    [0, 6, 10, 0],
    [0, 1, 18, 0],
  ],
  [
    [0, 4, 5, -2],
    [0, 7, 10, 1],
    [0, 2, 16, 1],
  ],
  [
    [0, 5, 5, -3],
    [0, 8, 10, 1],
    [0, 3, 14, 2],
  ],
  [
    [0, 5, 6, -3],
    [0, 9, 12, 1],
    [0, 3, 16, 2],
  ],
  [
    [0, 6, 6, -3],
    [0, 10, 14, 1],
    [0, 4, 18, 3],
  ],
  [
    [0, 6, 7, -4],
    [0, 11, 15, 1],
    [0, 4, 20, 3],
  ],
  [
    [0, 7, 8, -4],
    [0, 12, 17, 2],
    [0, 5, 22, 4],
  ],
  [
    [0, 7, 8, -4],
    [0, 13, 19, 2],
    [0, 5, 24, 4],
  ],
  [
    [0, 7, 9, -4],
    [0, 14, 21, 2],
    [0, 5, 26, 4],
  ],
  [
    [0, 8, 9, -5],
    [0, 15, 22, 2],
    [0, 6, 28, 5],
  ],
  [
    [0, 8, 10, -5],
    [0, 16, 24, 2],
    [0, 6, 30, 5],
  ],
  [
    [0, 8, 11, -5],
    [0, 17, 26, 2],
    [0, 6, 32, 5],
  ],
  [
    [0, 9, 11, -5],
    [0, 18, 28, 2],
    [0, 7, 34, 6],
  ],
  [
    [0, 9, 12, -6],
    [0, 19, 29, 2],
    [0, 7, 36, 6],
  ],
  [
    [0, 10, 13, -6],
    [0, 20, 31, 3],
    [0, 8, 38, 7],
  ],
  [
    [0, 10, 13, -6],
    [0, 21, 33, 3],
    [0, 8, 40, 7],
  ],
  [
    [0, 10, 14, -6],
    [0, 22, 35, 3],
    [0, 8, 42, 7],
  ],
  [
    [0, 11, 14, -7],
    [0, 23, 36, 3],
    [0, 9, 44, 8],
  ],
  [
    [0, 11, 15, -7],
    [0, 24, 38, 3],
    [0, 9, 46, 8],
  ],
];

const shadowKeyUmbraOpacity = 0.06;
const shadowKeyPenumbraOpacity = 0.03;
const shadowAmbientShadowOpacity = 0.05;

const shadowpacities = [shadowKeyUmbraOpacity, shadowKeyPenumbraOpacity, shadowAmbientShadowOpacity];

function createShadow(color: string, args: number | number[][]) {
  const data: number[][] = Array.isArray(args) ? args : shadows[args];
  return data
    .map((part, i) => `${part[0]}px ${part[1]}px ${part[2]}px ${part[3]}px ${alpha(color, shadowpacities[i])}`)
    .join(",");
}

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    colors: typeof THEME_PALETTE;
    priorities: { [key: string]: PaletteColor };
    util: {
      createShadow: (color: string, args: number | number[][]) => string;
      shadowData: number[][][];
    };
  }

  interface ThemeOptions {
    colors?: Partial<typeof THEME_PALETTE>;
    priorities?: { [key: string]: PaletteColorOptions };
    util?: {
      createShadow: (color: string, args: number | number[][]) => string;
      shadowData: number[][][];
    };
  }
}

export const base: ThemeOptions = {
  colors: THEME_PALETTE,
  palette: {
    primary: {
      main: THEME_PALETTE.primary,
      contrastText: THEME_PALETTE.white,
    },
    secondary: {
      main: THEME_PALETTE.secondary,
      contrastText: THEME_PALETTE.white,
    },
    error: {
      main: THEME_PALETTE.red,
      contrastText: THEME_PALETTE.white,
    },
    common: {
      white: THEME_PALETTE.white,
      black: THEME_PALETTE.black,
    },
    info: {
      main: THEME_PALETTE.primary,
    },
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontFamily: [
      "Poppins",
      "-apple-system",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body1: {
      fontSize: "0.9375rem",
    },
    body2: {
      fontSize: "0.8125rem",
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: "1.125rem",
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: "1.0625rem",
      lineHeight: 1.4,
    },
    h1: {
      fontFamily: "Apercu Pro",
      fontSize: "2.125rem",
      fontWeight: 400,
      letterSpacing: "-.03em",
    },
    h2: {
      fontSize: "1.8125rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h3: {
      fontSize: "1.5625rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h4: {
      fontSize: "1.3125rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h6: {
      fontSize: "0.9375rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
      lineHeight: 1.4,
    },
  },
  shadows: ["none"].concat(shadows.map((data) => createShadow(THEME_PALETTE.black, data))) as Shadows,
  overrides: {
    MuiTab: {
      root: {
        fontSize: "1.143em",
        textTransform: "none",
      },
    },
    MuiInputBase: {
      input: {
        height: "1em",
        lineHeight: 1.5,
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: "40px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "40px",
        lineHeight: 1.2,
        textTransform: "none",
      },
      contained: {
        padding: "12px 18px",
      },
      containedSizeSmall: {
        padding: "8px 12px",
      },
      containedSizeLarge: {
        padding: "16px 24px",
      },
      outlined: {
        padding: "11px 17px",
      },
      outlinedSizeSmall: {
        padding: "7px 11px",
      },
      outlinedSizeLarge: {
        padding: "15px 23px",
      },
      endIcon: {
        marginLeft: 4,
      },
      startIcon: {
        marginLeft: 0,
        marginRight: 4,
      },
      iconSizeMedium: {
        "&> *:first-child": {
          fontSize: 16,
        },
      },
      iconSizeLarge: {
        "&> *:first-child": {
          fontSize: 18,
        },
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiIconButton: {
      root: {
        "&$disabled": {
          opacity: 0.5,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "inherit",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0.25em",
        marginRight: "0.25em",
      },
    },
    MuiFormControlLabel: {
      root: {
        backgroundColor: alpha("#fff", 0.25),
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: "#DFE0EB",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
    MuiTouchRipple: {},
  },
  priorities: PRIORITIES_PALETTE,
  util: {
    createShadow,
    shadowData: shadows,
  },
};

export const light: ThemeOptions = {
  palette: {
    type: "light",
    text: {
      primary: lighten(THEME_PALETTE.black, 0.1),
      secondary: darken(THEME_PALETTE.grey, 0.5),
      disabled: "rgba(0, 0, 0, 0.35)",
      hint: "#c0c0c0",
    },
    divider: "rgba(0, 0, 0, 0.075)",
    background: {
      default: "#F7F8FC",
      paper: "#edf1f7",
    },
  },
};

export const dark: ThemeOptions = {
  palette: {
    type: "dark",
    text: {
      primary: darken(THEME_PALETTE.white, 0.1),
      secondary: THEME_PALETTE.secondary,
      disabled: "rgba(0, 0, 0, 0.35)",
      hint: "#c0c0c0",
    },
    divider: "rgba(0, 0, 0, 0.075)",
    background: {
      default: THEME_PALETTE.black,
      paper: "#e4e4ea",
    },
    common: {
      white: THEME_PALETTE.black,
      black: THEME_PALETTE.white,
    },
  },
};

export const createTheme = (options: ThemeOptions): Theme => {
  return responsiveFontSizes(muiCreateTheme(deepmerge({}, base, options)));
};

export const AppTheme = {
  Dark: createTheme(dark),
  Light: createTheme(light),
};

export default AppTheme;
